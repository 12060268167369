<template>
  <div class="StudyNewPageTopicSelector">
    <div :key="selectedTopicId" class="Topics">
      <div class="hidden mob:block mb-4 font-semibold text-primary-01">
        {{ $t('conversation_topics_title') }}
      </div>
      <div
        class="topics-wrapper grid grid-cols-8 tab:grid-cols-4 mob-only:flex mob-only:flex-nowrap mob-only:overflow-y-auto"
      >
        <template v-for="(topic, i) in filteredTopicsWithQuestions">
          <Topic
            v-if="topic.hideLockedTopics ? !topic.isLocked : true"
            :key="topic.id"
            :iterator="i"
            :selected="selectedTopicId === topic.id"
            :topic="topic"
            :conversation-streams="conversationStreams"
            class="mob-only:w-36 conversation-topic"
            data-e2e="selectTopic"
            @selectTopic="selectTopic({ topic })"
          >
          </Topic>
        </template>
      </div>

      <span ref="scroll-to"></span>

      <div
        v-if="!tailwindScreens.mobOnly"
        ref="topic-instructions-desktop"
        class="mt-12 Topics__instructions"
      >
        <div
          v-if="isTopicInstructionExist"
          class="text-2xl font-semibold text-primary-01"
          data-e2e="topicInstructionTitle"
        >
          {{ $t('conversation_topic_instructions_title') }}
        </div>
        <div v-if="topicFiles.length" class="Topics__files mt-3 mob-only:w-full" data-e2e="questionValue">
          <LLFileSlider
            class="Topics__files-slider"
            pre-large
            small-container
            :files="topicFiles"
          ></LLFileSlider>
        </div>
        <LLWysiwygOutput
          id="topicInstruction"
          class="mt-3 max-w-2xl"
          data-e2e="topicInstruction"
          :text="topicInstructions"
        ></LLWysiwygOutput>
      </div>
      <div class="hidden mob:block mt-8">
        <MobileTopicInfo
          v-if="selectedTopic"
          :index="selectedTopicIndex"
          :topic="selectedTopic"
          :conversation-streams="conversationStreams"
        ></MobileTopicInfo>
      </div>
      <LLSpoiler
        v-if="tailwindScreens.mobOnly && isTopicInstructionExist"
        ref="topic-instructions-mobile"
        class="mt-8"
        clean
        white
        :expanded="true"
      >
        <template #wrap-title>
          <div class="text-base leading-tight font-semibold text-primary-01">
            {{ $t('conversation_topic_instructions_title') }}
          </div>
        </template>
        <div v-if="topicFiles.length" class="Topics__files mb-3 mob-only:w-full" data-e2e="questionValue">
          <LLFileSlider
            class="Topics__files-slider"
            pre-large
            small-container
            :files="topicFiles"
          ></LLFileSlider>
        </div>
        <LLWysiwygOutput class="max-w-2xl" :text="topicInstructions"></LLWysiwygOutput>
      </LLSpoiler>
    </div>
  </div>
</template>

<script>
import Topic from '@/pages/Conversation/StudyNewPageTopicSelectorTopic.vue'
import MobileTopicInfo from '@/pages/Conversation/Topics/MobileTopicInfo.vue'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput.vue'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import { screenCatcher } from '@/mixins/screenCatcher'
import SmoothScroll from 'smooth-scroll'

export default {
  name: 'StudyNewPageTopicSelector',
  components: { LLFileSlider, LLWysiwygOutput, MobileTopicInfo, Topic },
  mixins: [screenCatcher],
  props: {
    /**
     * @type {TopicModel[]}
     */
    topics: { type: Array, default: () => [] },
    /**
     * @type {QuestionModel[]}
     */
    conversationStreams: { type: Array, required: true, default: () => [] },
    selectedTopicId: { type: String, default: null }
  },
  computed: {
    selectedTopic() {
      return this.topics.find((topic) => topic.id === this.selectedTopicId)
    },
    selectedTopicIndex() {
      return this.filteredTopicsWithQuestions.indexOf(this.selectedTopic) + 1
    },
    orderedTopics() {
      return [...this.topics].sort((a, b) => a.order - b.order)
    },
    filteredTopics() {
      return this.orderedTopics.filter((topic) => !(topic.hideLockedTopics && topic.isLocked))
    },
    topicFiles() {
      return [...(this.selectedTopic?.files || [])].sort((a, b) => a.order - b.order)
    },
    topicInstructions() {
      return this.selectedTopic?.instructions
    },
    filteredTopicsWithQuestions() {
      return this.topics.filter((topic) => topic.questionIdList.length > 0)
    },
    isTopicInstructionExist() {
      if (this.topicInstructions) {
        return !!this.topicInstructions.replace(/<[^>]*>?/gm, '')
      } else if (this.topicFiles.length) {
        return true
      }
      return false
    }
  },
  watch: {
    selectedTopic: {
      handler(to, from) {
        if (!to && from) {
          this.selectFirstTopic()
        }
      }
    }
  },
  created() {
    this.selectFirstTopic()
  },
  methods: {
    selectFirstTopic() {
      const topic = this.filteredTopics.find((topic) => !topic.isLocked)
      topic && this.selectTopic({ topic })
    },
    selectTopic({ topic }) {
      if (topic.id === this.selectedTopicId) return
      this.$emit('update:selectedTopicId', topic?.id)
    },
    scrollToInstructions() {
      const scroll = new SmoothScroll()
      const scrollToBlock = this.$refs['scroll-to']

      if (scrollToBlock) {
        scroll.animateScroll(scrollToBlock, null, {
          offset: this.tailwindScreens.mobOnly ? 140 : 120,
          speedAsDuration: true,
          speed: 300,
          updateURL: false,
          popstate: false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageTopicSelector {
  .Topics {
    $root: &;
    .conversation-topic {
      @apply mob-only:w-36 mob-only:flex-shrink-0 mob-only:mr-4;
      &:last-child {
        @apply mr-0;
      }
    }

    .topics-wrapper {
      grid-gap: 16px;
      grid-auto-flow: dense;
      @screen mob-only {
        grid-gap: initial;
      }
    }

    &__instructions {
    }
    &__files {
      @apply min-w-0 flex-shrink-0;
      width: 190px;
      @screen mob-only {
        @apply w-full;
      }
      #{$root}__file-slider {
      }
    }
  }
}
</style>
