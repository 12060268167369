<template>
  <LLConfirmModalTransition>
    <LLConfirmModal class="StudyNewPageUnanswered">
      <template #title>
        {{ $t('unanswered-question-warning_title_normal') }}
      </template>
      <template #default>
        <div class="StudyNewPageUnanswered__content">
          <div class="StudyNewPageUnanswered__top-description">
            {{ $t('unanswered-question-warning_top-description') }}
          </div>
          <div v-for="question in questions" :key="question.id" class="StudyNewPageUnanswered__question">
            <div class="StudyNewPageUnanswered__question-number">
              {{
                $t('conversation-leave-warning_question-number', {
                  questionNumber: questionIndex(question.id)
                })
              }}
            </div>
            <div class="StudyNewPageUnanswered__question-title">{{ question.title }}</div>
          </div>
          <div class="StudyNewPageUnanswered__bottom-description">
            {{ $t('unanswered-question-warning_bottom-description') }}
          </div>
        </div>
      </template>
      <template #actions>
        <div class="StudyNewPageUnanswered__actions">
          <LLButton class="StudyNewPageUnanswered__action" small secondary @click.native="cancelClick">
            {{ $t('unanswered-question-warning_action-cancel') }}
          </LLButton>
          <LLButton class="StudyNewPageUnanswered__action" small secondary @click.native="notNowClick">{{
            $t('unanswered-question-warning_action_keep-going')
          }}</LLButton>
        </div>
      </template>
    </LLConfirmModal>
  </LLConfirmModalTransition>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import { StudyModel } from '@/models/study'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'

export default {
  name: 'StudyNewPageUnanswered',
  components: { LLConfirmModalTransition, LLConfirmModal, LLButton },

  props: {
    study: { type: StudyModel, required: true },
    /**
     * @type {TopicModel[]}
     */
    topics: { type: Array, default: () => [] },
    /**
     * @type {QuestionModel[]}
     */
    questions: { type: Array, required: true, default: () => [] },
    conversationStreams: { type: Array, required: true, default: () => [] },
    questionIndexes: { type: Object, default: () => {} }
  },
  methods: {
    questionIndex(questionId) {
      console.log(this.questionIndexes[questionId])
      return this.questionIndexes[questionId] !== undefined ? this.questionIndexes[questionId] + 1 : 0
    },
    cancelClick() {
      this.$emit('close')
    },
    notNowClick() {
      this.$emit('close', { withCallback: true })
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageUnanswered {
  &__top-description {
    @apply mb-5;
  }
  &__bottom-description {
    @apply mt-5;
  }
  &__content {
    max-width: 500px;
  }
  &__question {
    @apply flex items-center min-w-0;
    &-icon {
      @apply w-5 h-5 mr-3 flex-shrink-0;
      &-loading {
        @apply w-5 h-5;
      }
      &-warning {
        @apply w-full h-full text-status-03-600;
      }
      &-success {
        @apply w-full h-full text-status-01-600;
      }
    }
    &-number {
      @apply mr-2 text-button-01;
    }
    &-title {
      @apply flex-1 truncate;
    }
  }
  &__actions {
    @apply flex;
    @screen mob-only {
      @apply flex-col items-end;
    }
  }
  &__action {
    @apply ml-4;
    &:first-child {
      @apply ml-0;
    }
    @screen mob-only {
      @apply ml-0 mt-2;
      &:first-child {
        @apply mt-0;
      }
    }
  }
}
</style>
