<template>
  <div
    :class="{ Topic_inactive: inactive, Topic_selected: selected }"
    class="Topic p-4 cursor-pointer border border-neutral-01-50 rounded flex-col flex relative bg-neutral-01-15 hover:bg-white"
    @click="selectTopic"
  >
    <div class="absolute right-2.5 top-3.5">
      <NewMessageIcon v-if="isNewMessage" class="text-neutral-01"></NewMessageIcon>
      <LockIcon v-if="isLocked" class="text-neutral-01"></LockIcon>
    </div>
    <div class="flex flex-shrink-0 justify-between items-start">
      <div
        class="Topic-number uppercase text-xs text-neutral-01 font-bold"
        :class="{ 'text-neutral-01-400': isLocked }"
        data-e2e="topicNumber"
      >
        {{ $t('topic') }} {{ iterator + 1 }}
      </div>
    </div>
    <div
      class="mt-4 Topic-title text-base font-semibold text-primary-01-400 flex-grow truncate"
      :class="{ 'text-neutral-01-400': isLocked }"
      data-e2e="topicName"
    >
      {{ topic.name }}
    </div>
    <div v-if="!isLocked" class="mt-4">
      <div class="relative progress-line-container">
        <div
          class="helper mob-only:hidden -ml-2 z-10 -translate-y-2 transform transition opacity-0 duration-200 invisible absolute bottom-full mb-1 px-4 py-3 bg-white shadow-dropdown border box-border border-neutral-01-50"
        >
          <div class="flex items-center">
            <div class="rounded-full w-2 h-2 bg-answered-01"></div>
            <div class="text-xs text-neutral-01 ml-2 whitespace-nowrap text-wrap">
              {{ inProgressCount }} {{ $t('questions_answered') }}
            </div>
          </div>
        </div>
        <LLLineProgress class="cursor-pointer" :progress-list="lineTopicsProgress"></LLLineProgress>
      </div>
    </div>
    <div class="text-xs text-neutral-01 mt-4" data-e2e="questionNum">
      {{ questionsNum }} {{ $tc('question', questionsNum) }}
    </div>
  </div>
</template>

<script>
import LLLineProgress from '@/components/common/ui-components/LLLineProgress'
import { TopicModel } from '@/models/topic'
import { StreamState } from '@/common/enums'
import NewMessageIcon from '@/assets/icons/NewMessageIcon.vue'

export default {
  name: 'StudyNewPageTopicSelectorTopic',
  components: { NewMessageIcon, LLLineProgress },
  props: {
    topic: {
      type: TopicModel,
      default: null
    },
    iterator: {
      type: Number,
      default: 0
    },
    selected: {
      type: Boolean,
      default: false
    },
    conversationStreams: { type: Array, required: true, default: () => [] }
  },
  computed: {
    conversationStreamsObject() {
      return this.conversationStreams?.reduce((result, conversationStream) => {
        result[conversationStream.questionId] = conversationStream
        return result
      }, {})
    },
    isNewMessage() {
      return this.topic.questionIdList.find(
        (questionId) => this.conversationStreamsObject?.[questionId]?.hasNewMessage
      )
    },
    inactive() {
      return false
    },
    questionsNum() {
      return this.topic.questionIdList?.length
    },
    inProgressCount() {
      return this.topic.questionIdList.filter((questionId) => {
        return this.conversationStreamsObject[questionId]?.state === StreamState.ANSWERED
      }).length
    },
    inProgressPercentage() {
      return Math.round(((this.inProgressCount + this.completedCount) / this.questionsNum) * 100)
    },
    completedCount() {
      return this.topic.questionIdList.filter((questionId) => {
        return this.conversationStreamsObject[questionId]?.state === StreamState.RESOLVED
      }).length
    },
    completedPercentage() {
      return Math.round((this.completedCount / this.questionsNum) * 100)
    },
    isLocked() {
      return this.topic.isLocked
    },
    lineTopicsProgress() {
      return [
        {
          color: 'answered-01',
          progress:
            this.inProgressCount > 0 && typeof this.inProgressPercentage === 'number'
              ? this.inProgressPercentage
              : 0,
          name: 'inProgress'
        },
        {
          color: 'success-01',
          progress:
            this.completedCount > 0 && typeof this.completedPercentage === 'number'
              ? this.completedPercentage
              : 0,
          name: 'completed'
        }
      ]
    }
  },
  watch: {
    isLocked(value) {
      if (value && this.selected) {
        this.$emit('selectTopic', null)
      }
    }
  },
  methods: {
    selectTopic() {
      if (!this.isLocked) {
        this.$emit('selectTopic', { topic: this.topic })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Topic {
  &.Topic_selected {
    @apply border-primary-01-400 border-2;
  }

  &.Topic_inactive {
    .Topic-number {
      @apply text-neutral-01-400;
    }

    .Topic-title {
      @apply text-neutral-01-400;
    }
  }

  .progress-line-container {
    &:hover {
      .helper {
        @apply visible opacity-100 translate-y-0;
      }
    }
  }
}
</style>
