import Api from '@/services/api'
import studyMessages from '@/utils/modules/studyMessages'
import { QuestionModel } from '@/models/question'
import { v4 as uuidV4 } from 'uuid'
import { PromisePool } from '@supercharge/promise-pool'

class StudyNewPageBodyUtils {
  constructor({ studyId, topicId }) {
    this.questions = []
    this.isFetching = false
    this.isFetchingPart = false
    this.fetchError = null
    this.studyId = studyId
    this.topicId = topicId
    this.currentFetcherId = null
    this.callbackToNextSuccessFetch = null

    studyMessages.addEvent(studyMessages.enums.QUESTION_VISIBILITY_UPDATE, this.onQuestionVisibilityUpdate)
  }

  async fetchQuestions() {
    this.isFetching = true
    this.fetchError = false
    this.currentFetcherId = uuidV4()
    const currentFetcherId = this.currentFetcherId
    try {
      const { questions: serverQuestions } = await Api.studies.getStudyTopicsQuestions({
        studyId: this.studyId,
        topicId: this.topicId
      })
      if (this.currentFetcherId !== currentFetcherId) {
        return
      }
      this.questions = serverQuestions
        .map((question) =>
          QuestionModel.parseFromApi({ question, studyId: this.studyId, topicId: this.topicId })
        )
        .sort((q1, q2) => q1?.order - q2?.order)
      if (this.callbackToNextSuccessFetch) {
        this.callbackToNextSuccessFetch()
        this.callbackToNextSuccessFetch = null
      }
      this.isFetching = false
    } catch (e) {
      if (this.currentFetcherId !== currentFetcherId) {
        return
      }
      this.fetchError = e
      this.isFetching = false
    }
  }
  fetchQuestion = async ({ questionId }) => {
    this.isNewQuestionFetching = true
    try {
      const { question } = await Api.studies.getStudyTopicsQuestion({
        studyId: this.studyId,
        topicId: this.topicId,
        questionId: questionId
      })
      return QuestionModel.parseFromApi({ question, studyId: this.studyId, topicId: this.topicId })
    } catch (e) {
      throw e
    } finally {
      this.isNewQuestionFetching = false
    }
  }

  setTopicId = ({ topicId }) => {
    this.topicId = topicId
    this.questions = []
  }

  onQuestionVisibilityUpdate = ({ questionsToUpdate }) => {
    const asyncFunctions = []
    questionsToUpdate.forEach((questionToUpdate) => {
      if (questionToUpdate?.topicId === this.topicId) {
        const { questionId } = questionToUpdate
        if (questionToUpdate.isVisible) {
          asyncFunctions.push(async () => this.fetchQuestion({ questionId }))
        } else if (questionToUpdate.isVisible === false) {
          this.removeQuestion({ questionId })
        }
      }
    })
    if (asyncFunctions.length === 0) {
      return
    }
    this.isFetchingPart = true
    this.currentFetcherId = uuidV4()
    const currentFetcherId = this.currentFetcherId

    PromisePool.for(asyncFunctions)
      .withConcurrency(10)
      .process(async (asyncFucntion) => {
        return await asyncFucntion()
      })
      .then(({ results: newQuestions }) => {
        if (this.currentFetcherId !== currentFetcherId) {
          return
        }
        const filteredNewQuestions = newQuestions.filter(
          (question) => !this.questions.find((oldQuestion) => oldQuestion.id === question.id)
        )
        this.questions = [...this.questions, ...filteredNewQuestions].sort((q1, q2) => q1?.order - q2?.order)
        this.isFetchingPart = false
      })
      .catch(() => {
        if (this.currentFetcherId !== currentFetcherId) {
          return
        }
        this.isFetchingPart = false
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error',
          text: this.$t('error_fetch_new_question')
        })
      })
  }
  removeQuestion = ({ questionId }) => {
    this.questions = this.questions.filter((question) => question.id !== questionId)
  }

  destroy = () => {
    studyMessages.removeEvent(studyMessages.enums.QUESTION_VISIBILITY_UPDATE, this.onQuestionVisibilityUpdate)
  }

  addCallbackToNextSuccessFetch = ({ callback }) => {
    this.callbackToNextSuccessFetch = callback
  }
}
export default StudyNewPageBodyUtils
